import {Controller} from "@hotwired/stimulus"
// import Rails from '@rails/ujs';

export default class extends Controller {
    static targets = ['content', 'lineItem', 'warehouse', 'couponCheckbox']

    warehouseSelected(e) {
        const warehouseId = e.currentTarget.value

        this.warehouseTargets.forEach(target => {
            if (target.dataset.warehouseId == warehouseId) {
                target.classList.add('warehouse-selected')
            } else {
                target.classList.remove('warehouse-selected')
            }
        })

        this.lineItemTargets.forEach(target => {
            this.application.getControllerForElementAndIdentifier(target, 'fulfillment--line-item').warehouseSelected(warehouseId)
        })
    }

    warehouseSelectedProforma(e) {
        const warehouseId = e.currentTarget.value

        this.warehouseTargets.forEach(target => {
            if (target.dataset.warehouseId == warehouseId) {
                target.classList.add('warehouse-selected')
            } else {
                target.classList.remove('warehouse-selected')
            }
        })

        this.lineItemTargets.forEach(target => {
            this.application.getControllerForElementAndIdentifier(target, 'fulfillment--line-item').warehouseSelectedProforma(warehouseId)
        })
    }

    fulfillmentLinkClicked(e) {
        this.submitRequest(e)
            .then(html => {
                this.element.outerHTML = html
            })
    }


    contentLinkClicked(e) {
        this.submitRequest(e)
            .then(html => {
                this.contentTarget.innerHTML = html
            })
    }

    cancelFulfillment(e) {
        this.submitRequest(e)
            .then(html => {
                this.refreshFulfillments()
                this.orderController.refreshDetails(html)
            })
    }

    get orderController() {
        let orderElement = document.getElementById(`order_${this.data.get("order-id")}`)
        return this.application.getControllerForElementAndIdentifier(orderElement, "order")
    }

    refreshFulfillments() {
        const event = document.createEvent('CustomEvent')
        event.initCustomEvent('fulfillment-transition-completed', true, true, null)
        this.element.dispatchEvent(event)
    }

    submitRequest(e) {
        e.preventDefault()
        e.stopImmediatePropagation()

        const method = e.currentTarget.dataset.method || 'GET'

        return fetch(e.currentTarget.href, {
            method: method.toUpperCase(),
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => response.text())
    }

    toggleCoupon() {
        if ($(this.couponCheckboxTarget).is(":checked")) {
            this.applyCoupon()
        } else {
            this.removeCoupon()
        }
    }

    applyCoupon() {
        let url = `/admin/orders/${this.data.get("order-id")}/fulfillments/${this.data.get("id")}/coupons`

        return fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => {
                if (!response.ok) {
                    $(".alert-container .col-xs-12").html(`<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">×</button>Unable to add coupon to order</div>`)
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                }
                return response.text()
            })
            .then(html => {
                this.orderController.refreshDetails(html)
            })
            .catch((error) => {
                console.error('Error:', error);
                $(".alert-container .col-xs-12").html(`<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">×</button>Unable to remove coupon from order</div>`)
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            });
    }

    removeCoupon() {
        let url = `/admin/orders/${this.data.get("order-id")}/fulfillments/${this.data.get("id")}/coupons`

        return fetch(url, {
            method: 'DELETE',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => {
                if (!response.ok) {
                    $(".alert-container .col-xs-12").html(`<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">×</button>Unable to remove coupon from fulfillment</div>`)
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                }
                return response.text()
            })
            .then(html => {
                this.orderController.refreshDetails(html)
            })
            .catch((error) => {
                console.error('Error:', error);
                $(".alert-container .col-xs-12").html(`<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">×</button>Unable to remove coupon from fulfillment</div>`)
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            });
    }
}
